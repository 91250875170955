import React from "react"
import { Button } from "antd"
import { baseUrlImg } from "../../config/api"
import images from "themes/images"

export default function ModulePopUpBack({ service_s, handleFlip }) {
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  return (
    <div
      className={`leftCol_Module Popup4 ${
        service_s && service_s?.services && service_s?.services[0]?.type === 0
          ? "game"
          : ""
      }`}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div className="row">
        <div className="Logo">
          <div className="Logo--Help">
            <img
              // src={
              //   isEPay ||
              //   (service_s && service_s?.services && service_s?.services[0]?.icon)
              //     ? `${baseUrlImg}/${
              //         service_s?.services[0]?.icon || "uncategorized/placeholder.jpg"
              //       }`
              //     : images[service_s.id]
              // }
              src={`${baseUrlImg}/${
                service_s && service_s?.services && service_s?.services[0].icon
              }`}
              alt=""
            />
          </div>
        </div>
        <span className="title">Guida alla {service_s?.name}</span>
        <div
          className="content"
          style={{
            backgroundColor:
              service_s && service_s?.services && service_s?.services[0].colorBg,
            color:
              service_s && service_s?.services && service_s?.services[0].colorTesto,
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "45px",
                  height: "45px",
                  background: "white",
                  borderRadius: "50%",
                  margin: 5,
                }}
              >
                <img
                  // src={
                  //   isEPay ||
                  //   (service_s &&
                  //     service_s?.services &&
                  //     service_s?.services[0]?.icon)
                  //     ? `${baseUrlImg}/${
                  //         service_s?.services[0]?.icon ||
                  //         "uncategorized/placeholder.jpg"
                  //       }`
                  //     : images[service_s.id]
                  // }
                  src={`${baseUrlImg}/${
                    service_s && service_s?.services && service_s?.services[0].icon
                  }`}
                  alt=""
                  style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: "50%" }}
                />
              </div>

              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                {service_s?.name}
              </span>
            </div>
            <div
              className="extra-info"
              style={{
                color:
                  service_s &&
                  service_s?.services &&
                  service_s?.services[0].colorTesto,
                textAlign: "justify",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  service_s && service_s?.services && service_s?.services[0].content,
              }}
            ></div>
          </div>

          <Button onClick={handleFlip}>Chiudi</Button>
        </div>
      </div>
    </div>
  )
}
