import React, { Component, Fragment } from "react"
import { includes } from "lodash"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import { skin } from "config/api"

import "swiper/swiper.scss"
import { newAzioni, newAzioniSubmenu } from "config"
import "./newAzioni.css"
import Statistiche from "../Statistiche/Statistiche"
class Azioni extends Component {
  state = {
    active: { url: "dashboard/ricariche" },
    className: "",
    classNameBelongTo: "",
  }
  componentDidMount() {
    let url = window.location.href.slice(
      window.location.href.indexOf("#/") + "#/".length
    )
    this.setState({
      active: {
        ...(this.props?.match?.params.id || url === "forms"
          ? { url }
          : { url: "dashboard/ricariche" }),
      },
    })
  }

  componentDidUpdate(prevProps) {
    const id = this.props?.match?.params.id
    const previd = prevProps?.match?.params.id
    if (id !== previd) {
      this.props.togglePopUp(false)
    }
    if (id === "ricariche" && id !== previd) {
      this.setState({ active: { url: `dashboard/${id}` } })
    }
    if (id === "pagamenti" && id !== previd) {
      this.setState({ active: { url: `dashboard/${id}` } })
    }
  }
  render() {
    const { active, activeMain, accountInfo, screenWidth, submenu } = this.props
    const userRole = accountInfo?.profile?.role?.name
      ? accountInfo?.profile?.role?.name
      : "noUser"

    const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}

    const newAzioniSubmenu = accountData?.dashboard

    let newAzioni = []
    if (newAzioniSubmenu) {
      newAzioni = [
        {
          id: 1,
          name: "Servizi",
          link: newAzioniSubmenu["dashboard"]?.[0]?.link || "fallback-link",
          active: "dashboard",
          i: "fal fa-list-alt",
          displayRole: ["super_admin", "agency", "agent", "user", "noUser"],
        },
        {
          id: 2,
          name: "SUPPORT",
          link: newAzioniSubmenu["support"]?.[0]?.link || "fallback-link",
          active: "support",
          i: "fal fa-user-headset",
          displayRole: ["super_admin", "agency", "agent", "user", "support"],
        },
        {
          id: 3,
          name: "SHOP",
          link: "products", // Provide a default link or use a fallback link
          active: "products",
          i: "fal fa-store",
          displayRole: ["super_admin", "agency", "agent", "user"],
          new: true,
        },
        {
          id: 4,
          name: "Contabilità",
          link: newAzioniSubmenu["contabilita"]?.[0]?.link || "fallback-link",
          active: "contabilita",
          i: "fal fa-wallet",
          displayRole: ["super_admin", "agency", "agent", "user"],
        },
        {
          id: 5,
          name: "Statistiche",
          link: "reportistica",
          active: "reportistica",
          type: "span",
          i: "fal fa-analytics",
          displayRole: ["super_admin", "agency", "agent", "user"],
        },
        {
          id: 6,
          name: "AREA DOWNLOAD",
          link: "areaDownload",
          active: "areaDownload",
          i: "fal fa-download",
          displayRole: ["super_admin", "agency", "agent"],
        },
      ]
    }

    return (
      screenWidth > 1024 && (
        <Fragment>
          <Statistiche userRole={userRole} />
          <div className="MenuC">
            <div
              className="Menu maxWidth"
              style={userRole === "noUser" ? { display: "none" } : {}}
            >
              {newAzioni &&
                Array.isArray(newAzioni) &&
                newAzioni.map((azioni) => {
                  return (
                    includes(azioni.displayRole, userRole) && (
                      <div
                        key={azioni.id}
                        className={`${
                          azioni.active === activeMain ? "active" : "none"
                        }`}
                      >
                        {azioni.new && <span className="newAz">new</span>}
                        {azioni.type === "span" ? (
                          <a
                            href={window.location.hash}
                            onClick={() => {
                              this.props.editReportistica(
                                !this.props.ReportisticaDet
                              )
                            }}
                          >
                            <div>
                              <i className={azioni.i} />
                              <span>{azioni.name}</span>
                            </div>
                          </a>
                        ) : (
                          <a href={"#/" + azioni.link}>
                            <div>
                              <i className={azioni.i} />
                              <span>{azioni.name}</span>
                            </div>
                          </a>
                        )}
                      </div>
                    )
                  )
                })}
            </div>
          </div>
          {submenu !== "noSubmenu" && (
  <div className="Submenu">
    <div className="MenuS maxWidth">
      {(() => {

        const items = newAzioniSubmenu[activeMain] || [];
        const regularItems = items.filter(item => item.name !== "PRENOTAZIONI" && item.name !== "RICHARICHE");
        const prenotazioniItem = items.find(item => item.name === "PRENOTAZIONI");

     
        const renderItem = (item) => (
          includes(item.displayRole, userRole) &&
          !(item.disallowedSkinIds || []).includes(skin.skin_id) && (
            <div
              id={item.link}
              key={item.id}
              onClick={() => {
                if (activeMain === "dashboard") {
                  this.setState({
                    active: { ...{ url: item.link } },
                  });
                }
              }}
              className={`${
                active === "visure" && item.name === "VISURE"
                  ? "active"
                  : active === "visure"
                  ? "none"
                  : active === "spedizioni" && item.name === "SPEDIZIONE"
                  ? "active"
                  : active === "spedizioni"
                  ? "none"
                  : activeMain === "dashboard"
                  ? item.link === this.state.active.url
                    ? "active"
                    : "none"
                  : active === item.link
                  ? "active"
                  : "none"
              }`}
            >
              {item.type === "span" ? (
                <label>
                  <div>
                    <span>{item.name}</span>
                  </div>
                </label>
              ) : (
                <a href={"#/" + item.link}>
                  <div>
                    <span>{item.name}</span>
                  </div>
                </a>
              )}
            </div>
          )
        );


        const renderedRegularItems = regularItems.map(renderItem);


        const renderedPrenotazioniItem = prenotazioniItem ? renderItem(prenotazioniItem) : null;

        return (
          <>
            {renderedRegularItems}
            {renderedPrenotazioniItem}
          </>
        );
      })()}
    </div>
  </div>
)}

        </Fragment>
      )
    )
  }
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  ReportisticaDet: state.auth.ReportisticaDet,
  screenWidth: state.main.screenWidth,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(Azioni)
